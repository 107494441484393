.custom-accordion .accordion-icon {
    position: absolute;
    right: 3rem;
    transition: all 0.3s;
}

.custom-accordion .card-body {
    display: none;
}

.custom-accordion .accordion-active .card-body {
    display: block;
}

.accordion-active .accordion-icon {
    transform: rotate(180deg);
}
.customs-accord .card-header {
    cursor: pointer;
}

.accordion-headers  {
    background-color: #eff7fd !important;
    font-size: 1rem!important;
    border-radius: 0!important;
    margin-right: 0;
    color: #525253;
    font-family: "ErasDemiITC";
    height: 4rem;

}

.item-card  {
     margin-bottom: 1rem;
}

.title-header {
    display: flex;
    align-items: center;
    padding: 0px 19px 0px 50px;
    color: #00a0e3;
    background-color: #eff7fd !important;
    border-bottom: 0px solid rgba(0,0,0,.125)!important;
}
 
